import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {Message} from "./Conversation";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import {SimpleLoader} from "@components/SimpleLoader";
import {ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {config} from "../../../config";
import {StorageKey_JWT} from "@intuitionrobotics/user-account/frontend";

function ParamRenderer({paramKey, value, messageId}: { paramKey: string, value: any, messageId: string }) {
    if (paramKey === "sessionId") {
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const query = `SELECT *
                       FROM \`${config.BigQueryModule.dataSet}.raw_data.speech_processing\`
                       WHERE sessionId = '${value}'
                         AND partDate = '${formattedDate}'`;

        const bigQueryUrl = `https://console.cloud.google.com/bigquery?project=${config.BigQueryModule.dataSet}&ws=!1m0&page=queryeditor`;
        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            navigator.clipboard.writeText(query.trim()).then(() => {
                ToastModule.toastInfo("Query copied to clipboard! Redirecting...", 3000);
                console.log("Query copied to clipboard! You can paste it in the BigQuery editor.");
                setTimeout(() => window.open(bigQueryUrl, '_blank'), 2000);
            }).catch(err => {
                console.error("Failed to copy query to clipboard: ", err);
            });
        };

        return (
            <div key={`${messageId}_${paramKey}`}>
                {paramKey}:
                <a
                    onClick={handleClick}
                    style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                >
                    {value}
                </a>
            </div>
        );
    }
    return (
        <div key={`${messageId}_${paramKey}`}>
            {paramKey}: {value}
        </div>
    );
}

enum FeedbackState {
    NoNeed,
    Pending,
    AskingPrompt,
    Done,
    Error
}

// TODO: add readable timestamp
function MessageRenderer({message}: { message: Message }) {
    const [showExtraParams, setShowExtraParams] = useState(false);
    const [showFeedback, setShowFeedback] = useState<FeedbackState>(message.showFeedback ? FeedbackState.Pending : FeedbackState.NoNeed);
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState("");

    const setError = () => {
        setShowFeedback(FeedbackState.Error);
        ToastModule.toastError("Failed to send feedback, try again later!");
        setTimeout(() => setShowFeedback(FeedbackState.Pending), 2000);
    };

    const sendFeedback = (feedback: boolean, prompt?: string) => {
        setLoading(true);
        const feedbackBody = {
            session_id: message.extraParams.sessionId,
            feedback,
            prompt,
            provider: message.extraParams.service as string
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + StorageKey_JWT.get()
            },
            body: JSON.stringify(feedbackBody)
        };

        fetch(config.KasperModule.origin + "/v1/interaction/feedback", options)
            .then(response => {
                setLoading(false);
                if (response.ok)
                    return setShowFeedback(FeedbackState.Done);

                setError();
            }).catch(setError);
    };

    const renderChecks = () => [true, false].map(feedback => {
        const s = feedback ? "up" : "down";
        return <img
            key={`${message.id}${s}`}
            className={"clickable"}
            onClick={(e) => {
                e.stopPropagation();
                if (!feedback)
                    return setShowFeedback(FeedbackState.AskingPrompt);

                sendFeedback(true);
            }}
            alt={`feedback_${s}`}
            src={require(`@res/images/thumb_${s}.svg`)}
            width={50}
        />;
    });

    const enterPressed = () => {
        setMsg("");
        sendFeedback(false, msg);
        setLoading(true);
    };
    const shouldShowExtraParamRelated = message.extraParams && showExtraParams;
    const renderText = () => {
        if (showFeedback === FeedbackState.NoNeed)
            return message.text;

        const isAskingForPrompt = showFeedback === FeedbackState.AskingPrompt;
        const style: CSSProperties = {display: "flex"};
        if (isAskingForPrompt)
            style.flexDirection = "column"

        let otherComponent;
        switch (showFeedback) {
            case FeedbackState.AskingPrompt:
                otherComponent = <div className={"ll_h_c space-between"} onClick={e => e.stopPropagation()}>
                    <TextField
                        id="feedback-input"
                        variant="standard"
                        onChange={(e) => setMsg(e.target.value)}
                        placeholder="Type your message..."
                        onKeyDown={e => e.key === "Enter" && enterPressed()}
                        value={msg}
                    />
                    <Button
                        variant="contained"
                        onClick={() => enterPressed()}
                    >Send</Button>
                </div>;
                break;
            case FeedbackState.Done:
                otherComponent = <img
                    onClick={e => e.stopPropagation()}
                    key={'done_feedback'}
                    alt={`feedback_done`}
                    src={require(`@res/images/check_blue.svg`)}
                    width={50}
                />;
                break;
            case FeedbackState.Pending:
                otherComponent = renderChecks();
                break;
            case FeedbackState.Error:
                otherComponent = <img
                    onClick={e => e.stopPropagation()}
                    key={'error_feedback'}
                    alt={`feedback_error`}
                    src={require(`@res/images/icon__x_red.svg`)}
                    width={50}
                />;
                break;
            default:
                otherComponent = null;
        }
        return <div style={style}>
            {message.text}
            {otherComponent}
        </div>;
    };

    return (
        <div
            key={message.id}
            className={`message ${(message.sender).toLowerCase()}-message`}
            onClick={() => {
                if (showExtraParams)
                    return;
                setShowExtraParams(true)
            }}
        >
            {shouldShowExtraParamRelated && <div className={"close"} onClick={() => setShowExtraParams(false)}>X</div>}
            {renderText()}
            {message.translatedText && <div className="translated-text">{message.translatedText}</div>}
            {shouldShowExtraParamRelated &&
                <div className="extra-params">
                    {Object.keys(message.extraParams).map(key => <ParamRenderer
                            key={message.id + key}
                            paramKey={key}
                            value={message.extraParams[key]}
                            messageId={message.id}
                        />
                    )}
                </div>
            }
            {loading && <SimpleLoader noText={true} absolute={true}/>}
        </div>
    );
}

type ChatProps = {
    messages: Message[],
    onToggle: (toggled: boolean) => void,
    onMessageSent: (message: string) => void,
    isToggled: boolean | undefined
};

export function RenderChat({messages, onMessageSent, onToggle, isToggled}: ChatProps) {
    const [typedMessage, setTypedMessage] = useState("");
    const [showInput, setShowInput] = useState(isToggled)
    const [loading, setLoading] = useState(false)

    let t: any;
    const enterPressed = () => {
        t && clearTimeout(t);
        setTypedMessage("");
        onMessageSent(typedMessage);
        setLoading(true);
        t = setTimeout(() => setLoading(false), 3000);
    };

    useEffect(() => {
        setShowInput(isToggled);
    }, [isToggled]);

    useEffect(() => {
        setLoading(false);
        t && clearTimeout(t);
    }, [messages.length]);

    return (
        <div className="conversation-container">
            <div className={"ll_h_c space-between"}>
                <h2>Conversation</h2>
                {isToggled !== undefined && <Switch
                    onClick={() => {
                        setShowInput(s => {
                            onToggle(!s)
                            return !s;
                        })
                    }}
                    checked={showInput}
                    inputProps={{'aria-label': 'controlled'}}
                />}
            </div>
            <div className="message-container">
                {messages.map((message) => <MessageRenderer key={message.id} message={message}/>)}
            </div>
            {showInput && <div className={"ll_h_c space-between"}>
                <TextField
                    id="filled-basic"
                    variant="standard"
                    onChange={(e) => setTypedMessage(e.target.value)}
                    placeholder="Type your message..."
                    onKeyDown={e => e.key === "Enter" && enterPressed()}
                    value={typedMessage}
                />
                <Button
                    variant="contained"
                    onClick={() => enterPressed()}
                >Send</Button>
            </div>}
            {loading && <SimpleLoader backgroundColor={"transparent"} absolute={true}/>}
        </div>)
}
