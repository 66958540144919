import * as React from 'react';
import {useEffect, useState} from 'react';
import './style.css';
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {Second} from "@intuitionrobotics/ts-common";
import {GetFirebaseToken} from "@app-sp/app-shared/api";
import {FirebaseModule} from "@intuitionrobotics/firebase/frontend";
import {RenderChat} from "./RenderChat";
import {PushMessagesModule} from "@modules/PushMessagesModule";
import {Elliq_ProductKey} from '@app/ir-q-app-common/shared/consts';
import {AppPackage_ElliQ} from '@app/ir-q-app-common/types/push-messages';

export type Message = {
    id: string;
    sender: Sender;
    text: string;
    translatedText?: string;
    extraParams?: any;
    showFeedback?: boolean;
};

enum Sender {
    User = 'User',
    ElliQ = 'ElliQ',
    System = 'System',
    Plan = 'Plan'
}

const getToken = async (unitId: string) => {
    const r = await XhrHttpModule
        .createRequest<GetFirebaseToken>(HttpMethod.GET, "get-firebase-token")
        .setUrlParams({projectId: "ir-q-state-dashboard-staging", unitId: unitId})
        .setRelativeUrl(`/v1/token/get`)
        .setTimeout(60 * Second)
        .setLabel(`Listening to collection`)
        .setOnError(`Failed to listen to collection`)
        .executeSync();
    return r.token
}

const translate = async (text: string) => {
    return XhrHttpModule
        .createRequest<any>(HttpMethod.GET, "translate")
        .setUrlParams({text, from: "ja-JP", to: "en-US"})
        .setRelativeUrl(`/v1/translate`)
        .setTimeout(60 * Second)
        .setLabel(`Translate`)
        .executeSync();
}

const sendPush = (unitId: string, type: string, data?: string) => PushMessagesModule.pushMessageToUnits([{unitId, product: Elliq_ProductKey}], type, data, ["som"], AppPackage_ElliQ, 10000);

function Conversation(props: { unitId: string, textEnabled?: boolean }) {
    const unitId = props?.unitId;
    if (!unitId)
        return;
    const [messages, setMessages] = useState<Message[]>([]);

    const handleUserInput = (interaction: any) => {
        let toAdd: any = getDataParams(interaction);
        try {
            const {parameters: intentParams, ...rest} = toAdd;
            const p = JSON.parse(intentParams);
            Object.entries(p).forEach(([key, value]) => {
                rest[`param ${key}`] = value;
            })
            toAdd = rest;
        } catch (e) {
            console.error(e);
        }
        toAdd.intent = interaction.message;
        addMessage(interaction.id, Sender.User, interaction.params.text || interaction.message, toAdd);
    };

    function handleAction(interaction: any) {
        switch (interaction.type) {
            // case 'UI_ACTION':
            //     addMessage(Sender.System, `UI: ${interaction.message}`, getDataParams(interaction));
            //     break;
            // case 'MOVE':
            //     addMessage(Sender.System, `M: ${interaction.params?.gestureName}`, getDataParams(interaction));
            //     break;
            default:
                console.log(interaction.type, JSON.stringify(interaction));
        }
    }

    const getDataParams = (interaction: any) => ({sessionId: interaction.sessionId, planName: interaction.planName, ...interaction.params});

// const [typedMessage, setTypedMessage] = useState("")
    const addDataToConversation = (data: any) => {
        data?.forEach((interaction: any) => {
            const dataParams = getDataParams(interaction);
            switch (interaction.provider) {
                case 'intent':
                    handleUserInput(interaction);
                    break;
                case 'wakeword':
                    addMessage(interaction.id, Sender.User, interaction.message);
                    break;
                case 'tts':
                    const originalText = interaction.message;
                    const text = originalText.replace(/<[^>]*>?/gm, '');
                    if (text !== originalText)
                        dataParams.originalText = originalText;

                    const showFeedback = ['GPT','GENEQ'].includes( dataParams.service)
                    addMessage(interaction.id, Sender.ElliQ, text, dataParams, showFeedback);
                    break;
                case 'action':
                    handleAction(interaction);
                    break;
                case 'plan_status':
                    addMessage(interaction.id, Sender.Plan, interaction.message, dataParams);
                    break;
                default:
                    console.log(interaction.provider, JSON.stringify(interaction));
            }
        })
    }

    useEffect(() => {
        if (!unitId)
            return;
        let unsub: any;
        getToken(unitId)
            .then(async (token) => {
                // @ts-ignore
                const sd = FirebaseModule.config.sd;
                const s = await FirebaseModule.createSession(sd, token);
                unsub = s.getDatabase().listen(`units/elliq/${unitId}/interaction/data`, (data: any) => addDataToConversation(data));
            })
            .catch(console.error);
        return () => {
            unsub && unsub();
        };
    }, [])

    const translateAndAdd = async (id: string, text: string) => {
        const translatedText = await translate(text)
        setMessages(s => {
            const idx = s.findIndex(m => m.text === text);
            if (idx === -1)
                return s;
            return [
                ...s.slice(0, idx),
                {
                    ...s[idx],
                    translatedText
                },
                ...s.slice(idx + 1)
            ]
        });
    };

    const addMessage = (id: string, sender: Sender, text: string, extraParams?: {}, showFeedback: boolean = false) => {
        // understand if the text is in japanese
        const isJapanese = text.match(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff]/);
        setMessages(s => [{id, sender, text, extraParams, showFeedback}, ...s]);
        if (isJapanese)
            translateAndAdd(id, text).catch(console.error);

    };

    return <RenderChat
        messages={messages}
        onToggle={(toggled) => {
            sendPush(unitId, "toggle-text-speech-server", toggled.toString());
        }}
        onMessageSent={(message) => {
            const value: any = {transcript: message};
            const planMessages = messages.filter(m => m.sender === Sender.Plan);
            if (planMessages.length && planMessages[0].extraParams?.status?.toLowerCase() === "running")
                value.rootExecutionId = planMessages[0].extraParams?.rootExecutionId;

            const data = JSON.stringify(value);
            sendPush(unitId, "start-text-speech-server", data);
        }}
        isToggled={props.textEnabled}
    />
}

export default Conversation;